'use client';

import { Button } from '@repo/ui/components/ui/button';

export default function LogoutButton() {
  const handleLogout = () => {
    // Clear local storage and session storage
    localStorage.clear();
    sessionStorage.clear();

    // Since we're in the unauthenticated layout, we need to use the manual approach
    // Redirect to Auth0 logout endpoint manually
    const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'auth.inkeep.com';
    const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
    const returnTo = encodeURIComponent(window.location.origin);

    // Construct Auth0 logout URL
    let logoutUrl = `https://${domain}/v2/logout?`;
    if (clientId) {
      logoutUrl += `client_id=${clientId}&`;
    }
    logoutUrl += `returnTo=${returnTo}`;

    window.location.href = logoutUrl;
  };

  return (
    <Button variant="outline" onClick={handleLogout}>
      Log out
    </Button>
  );
}
